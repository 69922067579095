import { CartSettings, InjectedCartProps, withCart as withCartFactory } from '@grandvisionhq/next'

export const settings: CartSettings = {
  hideStockAndDeliveryOnLineItem: true,
  maximumQuantity: 5,
  isDiscountCodeEnabled: true,
  showCartDiscountOnLineItem: true,
  showSubTotal: true,
  showTotalDiscount: true,
}

export type { InjectedCartProps }

export const withCart = withCartFactory(settings)
